@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'
    ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.header{
  color: #2d2825;
  padding: 30px 20px 30px 20px;

}

#root {
  flex: 1;
}

.footer {
  margin-top: auto; /* Push footer to the bottom */
}

/* CSS */
input[type="radio"]:checked + label {
  background-color: lightblue; /* Change the background color as needed */
}


.image-item {
  width: 350px;
  height: 600px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: scale(1);
}

.image-item.fade-enter {
  opacity: 0;
  transform: scale(0.8);
}

.image-item.fade-enter-active {
  opacity: 1;
  transform: scale(1);
}

.text-shadow-black {
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

.fade-container {
    opacity: 0; /* Start with 0 opacity */
    transition: opacity 5s ease; /* Apply transition to opacity property */
}

.fade-container.fade-in {
    opacity: 1; /* When fade-in class is added, set opacity to 1 */
}


.flip-container {
    perspective: 1000px;
}

.flip-image {
    transition: transform 2.5s ease;
}

.flip-container.flip .flip-image {
    transform: rotateY(360deg);
}

/* Add this to your styles.css or a similar file */
.shadow-outline-white {
  text-shadow:
    -1px -1px 0 #fff,
     1px -1px 0 #fff,
    -1px  1px 0 #fff,
     1px  1px 0 #fff;
}
